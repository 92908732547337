import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pageable } from '@app/_shared';

@Injectable({providedIn: 'root'})
export class StoreService {

    constructor(private http: HttpClient) {
    }


    /**
     * Check store name availability
     * @param storeSlug
     */
    public checkStoreNameAvailability(storeSlug: string): Observable<any> {
        let url = `${environment.storeApiUrl}/store/available/${storeSlug}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(url, httpOptions);
    }

    /**
     * Check store name availability
     * @param storeSlug
     */
    public checkStoreNameAvailabilityForUpdate(id: string, storeSlug: string): Observable<any> {
        let url = `${environment.storeApiUrl}/store/${id}/available/${storeSlug}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(url, httpOptions);
    }

  public getPlanFeatures(storeID: string): Observable<any> {
    let url = `${environment.storeApiUrl}/billings/features`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'storeId': storeID,
      })
    };
    return this.http.get(url, httpOptions);
  }

    /**
     * Get Import code
     * @param code
     */
    public checkImportCode(code?: string): Observable<any> {
      let url = `${environment.storeApiUrl}/store/import-code/${code}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      return this.http.get(url, httpOptions);
    }

    /**
     * Check store exists
     * @param storeSlug
     */
    public checkStoreExists(storeID: string): Observable<any> {
        let url = `${environment.storeApiUrl}/store/exist/${storeID}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(url, httpOptions);
    }


    /**
     * Disable a store
     * @param storeID
     */
    public disableStore(storeID: string): Observable<any> {
        let url = `${environment.storeApiUrl}/store/updateDisabledByAdmin/${storeID}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.put(url, null, httpOptions);
    }

    /**
     * Enable a store
     * @param storeID
     */
    public enableStore(storeID: string): Observable<any> {
        let url = `${environment.storeApiUrl}/store/updateDisabledByAdmin/${storeID}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.put(url, null, httpOptions);
    }

    /**
     * Delete a store
     * @param storeID
     */
    public deleteStore(storeID: string): Observable<any> {
        let url = `${environment.storeApiUrl}/store/delete/${storeID}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.delete(url, httpOptions);
    }

    /**
     * Filter stores
     * @param filter
     * @param pageable
     */
    public filterStores(filter?: any, pageable?: Pageable): Observable<any> {
        let url = `${environment.storeApiUrl}/store/admin-filter`;
        let params = pageable ? pageable.getParams() : new HttpParams();
        if (filter?.searchText) {
            params = params.append('searchText', filter?.searchText);
        }
        if (filter?.ownerId) {
            params = params.append('ownerId', filter?.ownerId);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.get(url, httpOptions);
    }

  duplicateStore(storeID: string): Observable<any> {
    let url = `${environment.storeApiUrl}/store/duplicate/${storeID}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
    return this.http.post(url, null, httpOptions);
  }

  storesOverview(): Observable<any>{
    let url = `${environment.storeApiUrl}/store/overview`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

}

