import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from '@app/_shared/component/main-layout/main-layout.component';
import {AppAuthGuard} from '@app/_shared/guard/app.authguard';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AppAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
      },
      {
        path: 'seller/:sellerID',
        loadChildren: () => import('./seller/seller.module').then(m => m.SellerModule)
      },
      {
        path: 'affiliate/:affiliateID',
        loadChildren: () => import('./affiliate/affiliate.module').then(m => m.AffiliateModule)
      },
    ]
  },
  {
    path: 'error',
    loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: '**', redirectTo: 'error/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule {
}
