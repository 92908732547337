import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  faAngleDown,
  faExternalLink,
  faPlus,
  faUserAlt,
  faUserCircle, faUserCog, faUserFriends, faUserGear, faUserGraduate,
  faUsers,
  faUsersRays, faUserTag
} from '@fortawesome/free-solid-svg-icons';
import screenfull from 'screenfull';
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
  FeatureType, getAffiliateId, getSellerId,
  getStoreId,
  Pageable,
  StoreService, UserModel,
  YanLoaderService,
  YanToastrService
} from "@app/_shared";
import {Subscription} from "rxjs";
import {Store} from "@app/_shared/model/store/store.model";
import {storeMenuItems} from "@app/_shared/component/shopyan-layout/left-menu/menu";
import {FeatureAction} from "@app/_shared/enumeration/feature-action.enum";
import {UserService} from "@app/_shared/service/user.service";
import {StoreCacheService} from "@app/_shared/service/store-cache.service";
import {SocketService} from "@app/_shared/service/socket.service";
import {SellerCacheService} from "@app/_shared/service/seller-cache.service";
import {AffiliateCacheService} from "@app/_shared/service/affiliate-cache.service";

@Component({
  selector: 'app-shopyan-header',
  templateUrl: './shopyan-header.component.html',
  styleUrls: ['./shopyan-header.component.scss']
})
export class ShopyanHeaderComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  stores: Store[];
  filter : any = {};
  pageable = new Pageable();
  sellerId = getSellerId();
  affiliateId = getAffiliateId();
  seller: UserModel;

  /** Menus features **/
  menuItems = JSON.parse(JSON.stringify(storeMenuItems));
  featureAction = FeatureAction;
  featureType = FeatureType;

  /**  Icons **/
  faAngleDown = faAngleDown;
  faPlus = faPlus;
  faExternalLink = faExternalLink;

  sfull = screenfull;
  userName: string | undefined;
  avatar = 'ab';

  /** Notifications **/
  receivedData: string[] = [];

  /** Init counters */
  notifCount = 0;
  messageCount = 0;

  public locales: any = [
    {name: "French", code: "fr", country: "fr"},
    {name: "English", code: "en", country: "gb"},
    {name: "العربية", code: "ar", country: "ma"},
    //{name: "Deutsch", code: "de", country: "de"},
    //{name: "Español", code: "es", country: "es"},
  ]

  constructor(private keycloakService: KeycloakService,
              private translate: TranslateService,
              private router: Router,
              public storeCacheService: StoreCacheService,
              public sellerCacheService: SellerCacheService,
              public affiliateCacheService: AffiliateCacheService,
              private storeService: StoreService,
              private userService: UserService,
              private yanToastrService: YanToastrService,
              private yanLoaderService: YanLoaderService,
              public socketService: SocketService) {
  }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(isLogged => {
      if (isLogged) {
        this.keycloakService.loadUserProfile().then(profile => {
          this.userName = profile.username;
          this.avatar = this.userName ? this.userName.substring(0, 2) : 'ab';
        })
      } else {
        this.keycloakService.login({
          redirectUri: window.location.origin + this.router.url
        });
      }
    });
    // this.keycloakService.getToken().then(token => {
    //   this.socketService.connect(token);
    //   this.socketService.rxStomp.watch({destination: "/user/queue/notifs"})
    //     .subscribe((message) => {
    //       this.receivedData.push(message.body);
    //       this.notifCount++
    //     });
    //   this.socketService.rxStomp.watch({destination: "/user/queue/logout"})
    //     .subscribe((message) => {
    //       if (message.body == this.keycloakService.getKeycloakInstance().sessionId) {
    //         this.socketService.disconnect().then();
    //         this.keycloakService.login({
    //           redirectUri: window.location.origin + '/my-sites'
    //         });
    //         return;
    //       }
    //     });
    //   this.socketService.rxStomp.watch({destination: "/user/queue/msgs"})
    //     .subscribe((message) => this.messageCount++);
    // });
    if (this.sellerId && this.showSeller) {
      this.userService.getUserDetails(this.sellerId).subscribe(value => {
        this.sellerCacheService.initSellerCacheData(value);
      })
    }
    if (this.affiliateId && this.showAffiliate) {
      this.userService.getUserDetails(this.affiliateId).subscribe(value => {
        this.affiliateCacheService.initAffiliateCacheData(value);
      })
    }
    if(this.showStoresMenu){
      this.loadStores();
    }
  }

  /**
   * Show stores menu
   *
   */
  get showStoresMenu(): boolean {
    const path = window.location.pathname.split(';')[0]?.split('/');
    return path?.length > 3 && path[3] === 'store';
  }

  /**
   * Show seller
   *
   */
  get showSeller(): boolean {
    const path = window.location.pathname.split(';')[0]?.split('/');
    return path?.length > 1 && path[1] === 'seller';
  }

  /**
   * Show affiliate
   *
   */
  get showAffiliate(): boolean {
    const path = window.location.pathname.split(';')[0]?.split('/');
    return path?.length > 1 && path[1] === 'affiliate';
  }

  /**
   * Load stores
   */
  loadStores(): void {
    this.filter.ownerId = getSellerId();
    this.yanLoaderService.startLoader('stores-spinner');
    const sub = this.storeService.filterStores(this.filter, this.pageable).subscribe(response => {
      this.stores = response.data;
      this.yanLoaderService.stopLoader('stores-spinner');
    });
    this.subscription.add(sub);
  }

  /**
   * Go to store
   * @param storeId
   */
  gotoStore(storeId: string) {
    let url = this.router.url.replace(getStoreId(), storeId);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.yanLoaderService.startLoader('main-spinner');
    this.storeCacheService.getStoreCacheData(storeId).subscribe({
      next: data => {
        this.storeCacheService.initStoreCacheData(data);
        this.storeCacheService.fromStore = true;
        const feature = this.getUrlFeature(url);
        this.router.navigate([url]).then();
        this.yanLoaderService.stopLoader('main-spinner');
      }, error: error => {
        this.router.navigateByUrl('/').then();
        this.yanLoaderService.stopLoader('main-spinner');
      }
    });
  }

  getUrlFeature(url: string) {
    let feature = ''
    this.menuItems.every((value: any) => {
      if (value.feature && value.url == url) {
        feature = value.feature;
        return false;
      }
      const subFeature = value.subMenus?.every((value: any) => {
        if(value.url == url) {
          feature = value.feature;
          return false;
        }
        return true;
      });
      if(subFeature) return subFeature;
    });
    return feature;
  }

  /**
   * Toggle screen
   */
  fullScreenToggle(): void {
    if (screenfull.isEnabled) {
      screenfull.toggle().then();
    }
  }

  /**
   * Get selected local
   */
  get selectedLocale(): any {
    return this.locales.find((locale: any) => locale.code === this.translate.currentLang);
  }

  /**
   * Log out
   */
  logout() {
    this.userService.logout().subscribe();
    // this.socketService.disconnect().then();
    this.keycloakService.logout(window.location.origin + '/my-sites').then();
  }

  changeLanguage(lang: string) {
    this.userService.updateLang(lang).subscribe(() => {
      this.translate.use(lang);
    });
  }

  ngOnDestroy() {
    // this.socketService.disconnect().then();
    this.subscription.unsubscribe();
  }

  protected readonly getSellerId = getSellerId;
  protected readonly faUserCircle = faUserCircle;
  protected readonly faUserAlt = faUserAlt;
  protected readonly faUsers = faUsers;
  protected readonly faUsersRays = faUsersRays;
  protected readonly faUserGear = faUserGear;
  protected readonly faUserFriends = faUserFriends;
  protected readonly faUserCog = faUserCog;
  protected readonly faUserGraduate = faUserGraduate;
  protected readonly faUserTag = faUserTag;
}
