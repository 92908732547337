import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DatePipe} from "@angular/common";
import {DashboardFilter, OrderPeriodType} from "@app/_shared/model/stats/dashboard-filter.model";
import {getSellerHeader, getSellerId, Pageable} from "@app/_shared";

@Injectable({providedIn: 'root'})
export class StatsService {

  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }

  getFilterParams(filter?: DashboardFilter): HttpParams {

    let params = new HttpParams();

    if (filter?.periodType) {
      params = params.append('periodType', filter.periodType);
    }
    if (filter?.product) {
      params = params.append('product', filter.product);
    }

    if (filter?.startDate) {
      params = params.append('startDate', this.datePipe.transform(filter.startDate, "dd/MM/yyyy")!);
    }

    if (filter?.endDate) {
      params = params.append('endDate', this.datePipe.transform(filter.endDate, "dd/MM/yyyy")!);
    }

    return params;
  }

  /**
   * get visits by country
   */
  public getShopyanVisitsByCountry(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/visits-country`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getVisitsByCountry(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-country`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getLiveOrdersByCountry(): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-country`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Top visits by country
   */
  public getTopVisitsByCountry(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/top-location`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Top visits by country
   */
  public getPagesView(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/page-view`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by os
   */
  public getShopyanVisitsByOs(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/visits-os`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by os
   */
  public getVisitsByOs(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-os`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by device
   */
  public getShopyanVisitsByDevice(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/visits-device`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by device
   */
  public getVisitsByDevice(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-device`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getShopyanVisitsByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/visits-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getVisitsByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getShopyanVisitsAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/analytics-visits`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getVisitsAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/analytics-visits`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getLiveVisits(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/live-view`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getTodayVisits(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/today-visits`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by interval
   */
  public getOrdersByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by interval
   */
  public getShopyanOrdersByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/orders-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get AVERAGE ORDERS PER DAY
   */
  public getShopyanAvgOrdersByDay(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/orders-avg-day`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get AVERAGE ORDERS PER DAY
   */
  public getAvgOrdersByDay(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-avg-day`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get AVERAGE ORDERS PER HOUR
   */
  public getShopyanAvgOrdersByHour(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/orders-avg-hour`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get AVERAGE ORDERS PER HOUR
   */
  public getAvgOrdersByHour(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-avg-hour`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders analytics
   */
  public getShopyanOrdersAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/analytics-orders`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders analytics
   */
  public getOrdersAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/analytics-orders`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by payment mode
   */
  public getShopyanOrdersByPayment(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/orders-payments`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by payment mode
   */
  public getOrdersByPayment(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-payments`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }


  /**
   * get visits by payment mode
   */
  public getShopyanOrdersByPaymentAndDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/orders-payments-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by payment mode
   */
  public getOrdersByPaymentAndDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-payments-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by status
   */
  public getShopyanOrdersByStatus(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/orders-status`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by status
   */
  public getOrdersByStatus(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-status`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getShopyanSalesByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/sales-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getSalesByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/sales-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders analytics
   */
  public getShopyanSalesAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/analytics-sales`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders analytics
   */
  public getSalesAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/analytics-sales`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Inscriptions by date
   */
  public getInscriptionsByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/inscriptions-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Top Customers
   */
  public getTopCustomers(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/top-customers`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public getTopProducts(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/top-products`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public getSellerTopProducts(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/top-products`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public getOrderGraphData(periodType: OrderPeriodType): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/stats/${periodType}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get CA by interval
   */
  public getShopyanCaAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/analytics-ca`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Subscriptions by interval
   */
  public getShopyanSubsAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/analytics-subs`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Canceled subscriptions by interval
   */
  public getShopyanCancelSubsAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/analytics-canceled-subs`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by payment mode
   */
  public getSubsByPayment(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/subscriptions-payment`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by plan
   */
  public getSubsByPlan(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/subscriptions-plan`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by period
   */
  public getSubsByPeriod(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/subscriptions-period`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getStoresByCountry(filter?: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/stores-country`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: getSellerHeader(),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get jobs
   */
  public getJobs(pageable: Pageable, filter?: any): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/jobs`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter?.type) {
      params = params.append('type', filter.type);
    }
    if (filter?.status) {
      params = params.append('status', filter.status);
    }
    if (filter?.text) {
      params = params.append('text', filter.text);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }


  /**
   * get visits by country
   */
  public getGlobalLiveVisits(): Observable<any> {
    let url = `${environment.storeApiUrl}/shopyan-stats/live-view`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getGlobalLiveOrdersByCountry(): Observable<any> {
    let url = `${environment.orderApiUrl}/shopyan-stats/orders-country`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }
}

