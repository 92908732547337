import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Pageable} from "@app/_shared";
import {DashboardFilter} from "@app/_shared/model/stats/dashboard-filter.model";
import {DatePipe} from "@angular/common";

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }

  public filterUsers(filter: any, pageable: Pageable): Observable<any> {
    let url = `${environment.storeApiUrl}/user/filter`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter?.text) {
      params = params.append('text', filter.text);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);

  }

  public getConnectedUsers(): Observable<any> {
    let url = `${environment.storeApiUrl}/user/live-view`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);

  }

  public getUserDetails(uuid: string): Observable<any> {
    let url = `${environment.storeApiUrl}/user/${uuid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(url, httpOptions);
  }

  public updateEnabled(ids: string[], enabled: boolean): Observable<any> {
    let url = `${environment.storeApiUrl}/user/enabled`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    params = params.append('enabled', enabled);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.put(url, null, httpOptions);
  }

  /**
   * Logout from session
   */
  public isEmailVerified(): Observable<any> {
    let url = `${environment.storeApiUrl}/user/email-verified`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(url, httpOptions);
  }

  /**
   * Logout from session
   */
  public sendVerifyEmail(): Observable<any> {
    let url = `${environment.storeApiUrl}/user/verification-email`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(url, httpOptions);
  }

  public getLang(): Observable<any> {
    let url = `${environment.storeApiUrl}/user/lang`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      }),
      responseType: 'text' as 'json'
    };

    return this.http.get(url, httpOptions);
  }

  public updateLang(code: string): Observable<any> {
    let url = `${environment.storeApiUrl}/user/lang/${code}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.put(url, httpOptions);
  }

  /**
   * Logout from session
   */
  public logout(): Observable<any> {
    let url = `${environment.storeApiUrl}/user/logout`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(url, httpOptions);
  }

  sellerOverview() {
    let url = `${environment.storeApiUrl}/user/sellers/overview`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(url, httpOptions);
  }

  removeSellers() {
    let url = `${environment.storeApiUrl}/user/sellers/clean`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(url, httpOptions);
  }

  exportSellers(filter: DashboardFilter) {
    let url = `${environment.storeApiUrl}/user/export-sellers`;
    let params = new HttpParams();

    if (filter?.periodType) {
      params = params.append('periodType', filter.periodType);
    }

    if (filter?.startDate) {
      params = params.append('startDate', this.datePipe.transform(filter.startDate, "dd/MM/yyyy")!);
    }

    if (filter?.endDate) {
      params = params.append('endDate', this.datePipe.transform(filter.endDate, "dd/MM/yyyy")!);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }
}

