import {PlanType} from "@app/_shared/model/plan/plan-type.model";
import {PlanFeature} from "@app/_shared/model/plan/plan-feature.model";

export class Plan {
  id: string;
  type: PlanType;
  monthlyPrice: number;
  monthlyDiscount?: PDiscount;
  yearlyDiscount?: PDiscount;
  features: PlanFeature[];
}

export class PDiscount {
  fixedAmount: number;
  percent: number;
}
