<div id="shopyan-header">

  <div class="header-lp">
    <a [routerLink]="['/']" class="shopyan-logo"></a>

    <ng-container *ngIf="showSeller && sellerCacheService && sellerCacheService.seller">
      <a [routerLink]="['/seller/' + sellerCacheService.seller.id]" class="yan-btn secondary seller">
        <fa-icon [icon]="faUserAlt"></fa-icon>
        <span>{{sellerCacheService.seller.email}}</span>
      </a>

      <a [routerLink]="['/sellers']" class="yan-btn secondary sellers" *ngIf="!showStoresMenu || !storeCacheService.store">
        <fa-icon [icon]="faUsers"></fa-icon>
        Voir Tous les sellers
      </a>
    </ng-container>

    <ng-container *ngIf="showAffiliate && affiliateCacheService && affiliateCacheService.affiliate">
      <a [routerLink]="['/affiliate/' + affiliateCacheService.affiliate.id]" class="yan-btn secondary seller">
        <fa-icon [icon]="faUserTag"></fa-icon>
        <span>{{affiliateCacheService.affiliate.email}}</span>
      </a>

      <a [routerLink]="['/affiliates']" class="yan-btn secondary sellers" *ngIf="!showStoresMenu || !storeCacheService.store">
        <fa-icon [icon]="faUsersRays"></fa-icon>
        Voir Tous les affiliates
      </a>
    </ng-container>
    <ng-container *ngIf="showStoresMenu && storeCacheService.store">
      <div class="sites" [matMenuTriggerFor]="storesMenu" (click)="loadStores()">
        <span>{{storeCacheService.store.name}}</span>
        <fa-icon [icon]="faAngleDown"></fa-icon>
      </div>
      <a href="https://{{storeCacheService.store.defaultDomainName}}" target="_blank"
         class="yan-btn white-btn view-site right-ic">
        <span>{{ 'COMMON.SHOPYAN_HEADER.VIEW_MY_STORE' | translate }}</span>
        <fa-icon [icon]="faExternalLink"></fa-icon>
      </a>
    </ng-container>

  </div>
  <div class="header-rp">
    <div class="more" [matMenuTriggerFor]="MoreMenu"></div>
    <a [routerLink]="['/']" class="user-avatar">
      <span class="default-avatar">{{avatar}}</span>
    </a>
    <div class="ics">
      <div class="language" [matMenuTriggerFor]="localeMenu">
        <div class="flag" *ngIf="selectedLocale">
          <span class="fi fi-{{selectedLocale.country | lowercase}}"></span>
        </div>
      </div>
      <div class="fullscreen" [class.full]="sfull.isFullscreen" (click)="fullScreenToggle()">
      </div>
      <a [routerLink]="['/']" class="msgs">
        <div class="count">{{messageCount}}</div>
      </a>
      <div class="notifs" [matMenuTriggerFor]="NotifMenu">
        <div class="count">{{notifCount}}</div>
      </div>
    </div>
  </div>

</div>


<!-- MORE MENU -->
<mat-menu #MoreMenu>
  <div id="more-menu" class="yan-menu">
    <div class="yan-menu-padding">
      <a mat-menu-item [routerLink]="['/']">Overview</a>
      <a mat-menu-item [routerLink]="['/sellers']">Sellers</a>
      <a mat-menu-item [routerLink]="['/stores']">Stores</a>
      <a mat-menu-item [routerLink]="['/templates']">Templates</a>
      <a mat-menu-item [routerLink]="['/themes']">Themes</a>
      <a mat-menu-item [routerLink]="['/apps']">Applications</a>
      <a mat-menu-item [routerLink]="['/plans']">Plans</a>
      <a mat-menu-item [routerLink]="['/discounts']">Discounts</a>
      <a mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.HELP_CENTER' | translate }}</a>
      <a mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.COMMUNITY' | translate }}</a>
      <div class="sep"></div>
      <a (click)="logout()" mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.LOG_OUT' | translate }}</a>
    </div>
  </div>
</mat-menu>

<!-- STORES MENU -->
<mat-menu #storesMenu>
  <div id="sites-menu" class="yan-menu">
    <div class="yan-menu-padding">
      <div class="yan-menu-ttl">
        <span>Boutiques</span>
      </div>
      <div style="position: relative;">
        <div class="site-filter" (click)="$event.stopPropagation()">
          <input type="text" class="yan-input" placeholder="{{ 'COMMON.SHOPYAN_HEADER.FIND_STORE' | translate }}"
                 [(ngModel)]="filter.searchText"
                 (keyup.enter)="loadStores()"
          />
        </div>
        <ngx-spinner name="stores-spinner" type="ball-clip-rotate"></ngx-spinner>
        <ng-container *ngIf="stores && stores.length > 0">
          <div class="sites" *ngIf="stores && stores.length > 0">
            <ng-container *ngFor="let store of stores">
              <div class="site-item" (click)="gotoStore(store.id)">
                <div class="site-name">{{store.name}}</div>
                <div class="site-url">{{store.defaultDomainName}}</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!stores || stores.length == 0">
          <app-no-result></app-no-result>
        </ng-container>
      </div>
    </div>
    <a [routerLink]="['/seller/' + sellerId + '/stores']" class="yan-menu-actions">
      {{ 'COMMON.SHOPYAN_HEADER.GO_TO_ALL_SITES' | translate }}
    </a>
  </div>
</mat-menu>

<!-- LOCALE MENU -->
<mat-menu #localeMenu>
  <div id="locale-menu" class="yan-menu">
    <div class="locale-item" mat-menu-item (click)="changeLanguage(locale.code)" *ngFor="let locale of locales">
      <div class="flag"><span class="fi fi-{{locale.country | lowercase}}"></span>
      </div>
      <span>{{locale.name}}</span>
    </div>
  </div>
</mat-menu>


<!-- NOTIFICATIONS MENU -->
<mat-menu #NotifMenu>
  <div class="yan-menu" *ngFor="let data of receivedData">
    <div class="yan-menu-padding">
      {{data}}
    </div>
    <div class="sep"></div>
  </div>
</mat-menu>
