import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Direction} from "@angular/cdk/bidi";
import {UserService} from "@app/_shared/service/user.service";
import {KeycloakService} from "keycloak-angular";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

  loading = true;

  constructor(private translate: TranslateService,
              private keycloakService: KeycloakService,
              private userService: UserService) {
      translate.setDefaultLang('fr');
    this.keycloakService.isLoggedIn().then(isLogged => {
      if (isLogged) {
        this.userService.getLang().subscribe(lang => {
          if (lang && lang !== '') {
            this.translate.use(lang);
          } else {
            this.translate.use('fr');
          }
          this.loading = false;
        })
      } else {
        this.loading = false;
        this.translate.use('fr');
      }
    });
  }


  get direction(): Direction {
    if ('ar' === this.translate.currentLang) {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

}
