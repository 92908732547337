export class CheckoutField {
  id: string;
  type: CheckoutFieldType;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

export enum CheckoutFieldType {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  FULL_NAME = 'FULL_NAME',
  MAIL = 'MAIL',
  PHONE = 'PHONE',
  ADDRESS_1 = 'ADDRESS_1',
  ADDRESS_2 = 'ADDRESS_2',
  CITY = 'CITY',
  ZIP_CODE = 'ZIP_CODE',
  COUNTRY = 'COUNTRY',
  PROVINCE = 'PROVINCE',
  COMMENT = 'COMMENT'
}
