export enum MediaType {
    COLLECTION = 'COLLECTION',
    BRAND = 'BRAND',
    STORE = 'STORE',
    SUPPLIER = 'SUPPLIER',
    PRODUCT = 'PRODUCT',
    VARIANT = 'VARIANT',
    APPLICATION = 'APPLICATION',
    VERSION = 'VERSION',
}


export enum MediaSize {
  small = 'small',
  original = 'original',
  medium = 'medium'
}
