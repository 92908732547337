export enum BuilderFieldType {
  INPUT = 'INPUT',
  TEXTAREA = 'TEXTAREA',
  IMAGE = 'IMAGE',
  COLOR = 'COLOR',
  SLIDER = 'SLIDER',
  SELECT = 'SELECT',
  LINK = 'LINK',
  MULTI_SELECT = 'MULTI_SELECT',
  SLIDE_TOGGLE = 'SLIDE_TOGGLE',
  EDITOR = 'EDITOR',
  CHIPS= 'CHIPS',
  MULTI_CHIPS= 'MULTI_CHIPS',
  RADIO= 'RADIO',
  CHECKBOX= 'CHECKBOX'
}
