import {Injectable} from '@angular/core';
import {UserModel} from '@app/_shared';

@Injectable({providedIn: 'root'})
export class AffiliateCacheService {

  affiliate : UserModel;
  initialized = false;

  constructor() {
  }


  /**
   * Init store cache data
   */
  public initAffiliateCacheData(data: UserModel) {
    this.affiliate = data;
    this.initialized = true;
  }

}

