import { MenuTargetType } from '@app/_shared';

export class Menu {
    id: string;
    name: string;
    menuItems: MenuItem[];
}

export class MenuItem {
    label: string;
    targetType: MenuTargetType;
    targetValue?: string;
}
