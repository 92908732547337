import {Injectable} from '@angular/core';
import {UserModel} from '@app/_shared';
import {SubscriptionStatus} from "@app/_shared/enumeration/subscription-status.enum";

@Injectable({providedIn: 'root'})
export class SellerCacheService {

  seller : UserModel;
  initialized = false;
  subscriptionStatus: SubscriptionStatus;

  constructor() {
  }


  /**
   * Init store cache data
   */
  public initSellerCacheData(data: UserModel) {
    this.seller = data;
    this.initialized = true;
  }

}

