import {PeriodType} from "@app/_shared";

export class UserModel {
    id: string;
    username: string;
    lastName: string;
    firstName: string;
    email: string;
    roles: string;
    enabled: boolean;
}

export class UserFilterModel {
  text: string;
  available?: boolean;

  period?: PeriodType;
}

export enum SellerSort {
  CREATE_DATE = 'createdDate',
}
