import { Customer } from '@app/_shared';

export class ProductReview {
    id: string;
    guest: string;
    product: string;
    customer: Customer;
    comment: string;
    rating: number;
    date: Date;
    disabled: boolean;
}
