import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {
  ExportOrderFilter, getSellerHeader,
  getSellerId,
  OrderFilter,
  OrderInvoiceFilter,
  OrderTreat,
  Pageable,
  Refund
} from '@app/_shared';
import {DatePipe} from "@angular/common";

@Injectable({providedIn: 'root'})
export class OrderService {

  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }

  count() {
    let url = `${environment.orderApiUrl}/orders/count`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      }),
      responseType: 'text' as 'json'
    };

    return this.http.get(url, httpOptions);
  }

  /**
   * Filter orders
   * @param storeId
   * @param filter
   * @param pageable
   */
  public filterOrders(filter: OrderFilter, pageable: Pageable): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/filter`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter?.text) {
      params = params.append('text', filter.text);
    }
    if (filter?.idCustomer) {
      params = params.append('idCustomer', filter.idCustomer);
    }
    if (filter?.status) {
      params = params.append('status', filter.status);
    }
    if (filter?.paymentStatus) {
      params = params.append('paymentStatus', filter.paymentStatus);
    }
    if (filter?.period) {
      params = params.append('period', '' + filter.period);
    }
    if (filter?.startDate) {
      params = params.append('startDate', this.datePipe.transform(filter.startDate, "dd/MM/yyyy")!);
    }
    if (filter?.endDate) {
      params = params.append('endDate', this.datePipe.transform(filter.endDate, "dd/MM/yyyy")!);
    }
    if (filter?.minAmount) {
      params = params.append('minAmount', filter.minAmount);
    }
    if (filter?.maxAmount) {
      params = params.append('maxAmount', filter.maxAmount);
    }
    params = params.append('archived', filter.archived);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Get orders to export
   * @param filter
   */
  public getOrdersToExport(filter: ExportOrderFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/export`;
    let params = new HttpParams();
    if (filter?.ids) {
      params = params.append('ids', filter.ids.join(','));
    }
    if (filter?.startDate) {
      params = params.append('startDate', this.datePipe.transform(filter.startDate, "dd/MM/yyyy")!);
    }
    if (filter?.endDate) {
      params = params.append('endDate', this.datePipe.transform(filter.endDate, "dd/MM/yyyy")!);
    }
    if (filter?.products) {
      params = params.append('products', filter.products.join(','));
    }


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }


  /**
   * Get last orders
   */
  public getSellerLastOrders(): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/seller-last`;

    const httpOptions = {
      headers: getSellerHeader(),
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Get last orders
   */
  public getLastOrders(): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/last`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Filter order timeLine
   * @param orderId
   * @param pageable
   */
  public getOrderTimeLine(orderId: string, pageable: Pageable): Observable<any> {
    const url = `${environment.orderApiUrl}/timeline-items/${orderId}`;
    let params = pageable ? pageable.getParams() : new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }


  /**
   * Archive orders
   * @param ids
   */
  public archiveOrders(ids: string[], archivate: boolean): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/archive`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    params = params.append('archivate', archivate);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.patch(url, null, httpOptions);
  }

  sendInvoice(id: string) {
    let url = `${environment.orderApiUrl}/orders/invoice/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.get(url, httpOptions);
  }

  duplicate(id: string) {
    let url = `${environment.orderApiUrl}/orders/duplicate/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };

    return this.http.post(url, null, httpOptions);
  }

  /**
   * Mark order as paid
   * @param ids
   */
  public markOrderAsPaid(id: string): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/markAsPaid/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.patch(url, httpOptions);
  }

  /**
   * Mark order as paid
   * @param ids
   */
  public markOrderAsUnPaid(id: string): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/markAsUnPaid/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.patch(url, httpOptions);
  }

  /**
   * Mark order as treated
   * @param ids
   */
  public markOrderAsTreated(id: string, orderTreat: OrderTreat): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/markAsTreated/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.put(url, orderTreat, httpOptions);
  }

  /**
   * Cancel order
   * @param ids
   */
  public cancelOrder(id: string, cancelReason: any): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/cancel/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.put(url, cancelReason, httpOptions);
  }


  /**
   * Refund OrderDetail
   * @param ids
   */
  public refundOrder(id: string, refund: Refund): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/refund/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.put(url, refund, httpOptions);
  }

  public filterOrderInvoices(filter: OrderInvoiceFilter, pageable: Pageable): Observable<any> {
    let url = `${environment.orderApiUrl}/invoices`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter?.text) {
      params = params.append('text', filter.text);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Download invoice
   * @param code
   */
  public downloadInvoice(code: string): Observable<any> {
    let url = `${environment.orderApiUrl}/invoices/${code}`;
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  resetStoreOrders(id: string) {
    let url = `${environment.orderApiUrl}/orders/reset/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.put(url, null, httpOptions);
  }

  removeOrders(start: any, end: any) {
    let url = `${environment.orderApiUrl}/orders/delete-orders`;
    let params = new HttpParams();
    if(start) params = params.append('start', start);
    if(end) params = params.append('end', end);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.put(url, null, httpOptions);
  }
}

