import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {KeycloakService} from 'keycloak-angular';
import {getStoreId} from '@app/_shared';
import {TranslateService} from "@ngx-translate/core";


@Injectable()
export class StoreHttpInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private translateService: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const storeId = getStoreId();
    if (storeId && !request.headers.get('storeId')) {
      request = request.clone({
        setHeaders: {
          'storeId': storeId
        }
      });
    }
    const acceptLanguage = this.translateService.currentLang || 'fr';

    request = request.clone({
      setHeaders: {
        'Accept-Language': acceptLanguage
      }
    });
    const ignoreError = request.headers.get('ignoreError');
    return next.handle(request).pipe(catchError(error => this.handleError(error, ignoreError)));
  }


  private handleError(response: HttpErrorResponse, ignoreError: any) {

    if (ignoreError) return throwError(response);

    let status = response.status;
    switch (status) {
      case 400:
        return throwError(response);
      case 404:
        this.router.navigateByUrl('/error/not-found').then();
        return throwError(response);
      case 401:
        this.keycloakService.isLoggedIn().then(isLogged => {
          if (isLogged) {
            this.router.navigateByUrl('/error/access-denied').then();
          } else {
            this.keycloakService.clearToken();
            this.keycloakService.login({
              redirectUri: window.location.origin + this.router.url
            });
            return;
          }
        });
        return EMPTY;
      case 403:
        this.router.navigateByUrl('/error/access-denied').then();
        return throwError(response);
      default:
        this.router.navigateByUrl('/error/oops').then();
        return throwError(response);
    }

  }


}
