export enum SectionType {
  HEADER = 'HEADER',
  CAROUSEL = 'CAROUSEL',
  CONTACT_FORM = 'CONTACT_FORM',
  FEATURED_COLLECTIONS = 'FEATURED_COLLECTIONS',
  COLLECTION_LIST= 'COLLECTION_LIST',
  COLLECTION= 'COLLECTION',
  FEATURED_PRODUCTS = 'FEATURED_PRODUCTS',
  PRODUCT_LIST= 'PRODUCT_LIST',
  PRODUCT = 'PRODUCT',
  FOOTER = 'FOOTER',
  IMAGE_BANNER = 'IMAGE_BANNER',
  MULTI_COLUMNS = 'MULTI_COLUMNS',
  RICH_TEXT = 'RICH_TEXT',
  NEWSLETTER = 'NEWSLETTER',
  YOU_MAY_PRODUCTS = 'YOU_MAY_PRODUCTS',
  VIDEO = 'VIDEO',
  EDITOR = 'EDITOR',
  CART = 'CART',
  CHECKOUT = 'CHECKOUT',
  ANNOUNCEMENT_BAR = 'ANNOUNCEMENT_BAR',
  REVIEWS= 'REVIEWS',
  COLLAGE= 'COLLAGE',
  IMAGE_TEXT= 'IMAGE_TEXT',
  BLOG_POST= 'BLOG_POST',
  COLLAPSIBLE_CONTENT = 'COLLAPSIBLE_CONTENT',
  LOGO_LIST = 'LOGO_LIST',
  FAVORITES = 'FAVORITES',
  IMAGE = 'IMAGE',
  IMAGES = 'IMAGES',
  MY_COLLECTIONS= 'MY_COLLECTIONS',
  MY_PRODUCTS= 'MY_PRODUCTS',
  STORE_REVIEWS= 'STORE_REVIEWS',
  PAGE_CONTENT= 'PAGE_CONTENT',
  SEPARATOR= 'SEPARATOR',
  PRODUCT_DESCRIPTION='PRODUCT_DESCRIPTION',
  COUNTDOWN= 'COUNTDOWN',
  TEXT_BANNER= 'TEXT_BANNER',
  INDICATOR= 'INDICATOR',
  CHECKOUT_BUTTON = 'CHECKOUT_BUTTON',
  OTHER = 'OTHER'
}
